// src/components/FeedbackPage.js

import React, { useState } from "react";
import Navbar from "../NavBar";
import { Footer } from "../LandingPage/components/Footer";
import api from "../../axios/axios";
import Notification from "../../components/Nofications/Notification";

function FeedbackPage() {
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Feedback submitted:", feedback);
    alert("Thank you for your feedback!");
    setFeedback(""); // Reset feedback input after submission

    if (String(feedback)?.trim()?.length > 0) {
      api
        .post("/user-choices/product-measures", {
          feedback,
          type: "sagebuyer-feedback",
        })
        .then((response) => {
          if (response.status === 200) {
            setSubmitted(true);
            console.log("User Choices  product-measures saved successfully");
          } else {
            console.error(
              "User Choices product-measures not saved. Please try again."
            );
          }
        })
        .catch((error) => {
          console.error(error);
          setSubmitted(null);
        });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {submitted && (
        <Notification
          right="30%"
          top="5%"
          isSuccess={submitted}
          onClose={() => setSubmitted(false)}
          msg={"Feedback submitted successfully"}
        />
      )}
      {submitted === null && (
        <Notification
          right="30%"
          top="5%"
          isSuccess={submitted}
          onClose={() => setSubmitted(false)}
          msg={"Something went wrong, please try again later"}
        />
      )}
      <Navbar />
      <div className="container mx-auto px-4 max-w-xl flex-grow">
        <h1 className="text-xl font-bold my-4">Feedback</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full p-2 border rounded"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Your feedback here..."
            rows="6"
          ></textarea>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors mb-10"
          >
            Submit Feedback
          </button>
        </form>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default FeedbackPage;
