import React from "react";

export const About = (props) => {
  return (
    <section className="bg-blue-50 dark:bg-gray-900">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">

          <p className="mb-4">
            We are a leading pioneer in corporate responsibility assessment, revolutionizing how these factors
            are measured and analyzed. The models we have developed have been in use at leading financial
            institutions for years. We balance traditional methods relying on human experts with cutting-edge
            Artificial Intelligence and Machine Learning (AI/ML) technologies.
          </p>
          <p className="mb-4">
            We also understand that values are personal. That's why our extension allows each user to define
            what matters most to them. From environmental impact to ethical sourcing, you can set your
            preferences and shop confidently, knowing that your choices are in sync with your beliefs.
            So, please help us improve our ratings by telling us what you think is important. Click here to
            complete a short survey.
          </p>
          <div className="flex justify-start">
            <a href="/onboarding"
              className="inline-block rounded-full bg-red-500 px-7 pb-2 pt-2 text-sm font-medium mb-5
           leading-normal text-white shadow-slate-800 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2
           focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2
            motion-reduce:transition-none">
              <span> Click here to complete a short survey</span>
            </a>
          </div>


        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img
            className="w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
            alt="office content 1"
          />
          <img
            className="mt-4 w-full lg:mt-10 rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
            alt="office content 2"
          />
        </div>

      </div>
      <h3 className="flex justify-center text-gray-600">U.S. Patent No.12106316</h3>
    </section>
  );
};
