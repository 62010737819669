import React, { useEffect, useState } from "react";

const topics = [
  "Climate Change",
  "Air Pollution",
  "Water Pollution",
  "Deforestation",
  "Loss of Biodiversity",
  "Ocean Acidification",
  "Soil Degradation",
  "Waste Management",
  "Plastic Pollution",
  "Overfishing",
  "Renewable Energy",
  "Carbon Footprint",
  "Greenhouse Gas Emissions",
  "Environmental Justice",
  "Sustainable Agriculture",
  "Human Rights",
  "Labor Rights",
  "Gender Equality",
  "Racial Equality",
  "Child Labor",
  "Fair Trade Practices",
  "Health and Safety Standards",
  "Access to Education",
  "Access to Healthcare",
  "Animal Rights",
  "Indigenous Rights",
  "Poverty Alleviation",
  "Affordable Housing",
  "Social Inclusion",
  "Digital Divide",
];

function Opinion() {
  const [selectedTopics, setSelectedTopics] = useState([]);

  const handleTopicChange = (topic) => {
    setSelectedTopics((prevSelected) => {
      if (prevSelected.includes(topic)) {
        return prevSelected.filter((t) => t !== topic);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, topic];
      }
      return prevSelected;
    });
  };

  return (
    <div className="container mx-auto max-w-xl flex-grow">
      <div>
        <h2 className="text-2xl font-bold my-4">
          Select up to 3 topics you care about:
        </h2>
        <p className="text-sm mb-4 text-slate-600">
          We are always evolving our methodology and we would like your input on
          additional factors we should consider.
        </p>
        {/* <p className="mb-2">Selected: {selectedTopics.length}/3</p> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {topics.map((topic) => (
            <div key={topic} className="flex items-center">
              <input
                type="checkbox"
                id={topic}
                checked={selectedTopics.includes(topic)}
                onChange={() => handleTopicChange(topic)}
                className="mr-2"
              />
              <label htmlFor={topic} className="text-sm">
                {topic}
              </label>
            </div>
          ))}
        </div>
        <div className="mt-6">
          {/* <button
            onClick={handleSubmit}
            disabled={selectedTopics.length !== 3}
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
          >
            Submit
          </button> */}
        </div>
      </div>
      <h1 className="text-lg font-bold my-4">
        What else would you be concerned about when buying a product?
      </h1>
      <textarea
        className="w-full p-2 border rounded"
        placeholder="Your answer here..."
        rows="6"
      ></textarea>
    </div>
  );
}

export default React.forwardRef(Opinion);
