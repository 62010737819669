import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from "react-share";


const SocialIcons = ({ shareableContent }) => {
    if (shareableContent?.generatedSentences && shareableContent?.generatedSentences?.areas) {
        shareableContent.title = `Just found out that ${shareableContent?.generatedSentences?.companyName} ${shareableContent?.generatedSentences?.sentence} 
        - ${shareableContent?.generatedSentences?.areas[0]} 
        - ${shareableContent?.generatedSentences?.areas[1]} 
        - ${shareableContent?.generatedSentences?.areas[2]}
        
        Checkout SageBuyer Extension for more info.`
    } else {
        shareableContent.title = `Download SageBuyer Extension`
        shareableContent.shareUrl = window.location.href
    }


    return (
        <div className="flex self-baseline items-baseline align-baseline">

            <div className="px-1">
                <TwitterShareButton
                    url={shareableContent?.shareUrl}
                    // hashTags={["#SageBuyer", "#ESG", "#SustainabilityShopping", "#ConsciousConsumer", "#EthicalShopping"]}
                    related={["sagebuyer"]}
                    title={shareableContent?.title}
                    className="px-1"
                >
                    <XIcon size={32} round />
                </TwitterShareButton>
            </div>

            <div className="px-1">
                <FacebookShareButton
                    url={shareableContent?.shareUrl}
                    // hashTags={["#SageBuyer", "#ESG", "#SustainabilityShopping", "#ConsciousConsumer", "#EthicalShopping"]}
                    related={["sagebuyer"]}
                    title={'shareableContent?.title'}
                    className="px-1">
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

                {/* <div>
                    <FacebookShareCount url={shareableContent?.shareUrl} className="px-1">
                        {count => count}
                    </FacebookShareCount>
                </div> */}
            </div>

            <div className="px-1">
                <div className="px-1">
                    <LinkedinShareButton
                        source={shareableContent?.shareUrl}
                        url={shareableContent?.shareUrl}
                        title={"Checkout SageBuyer Extension"}
                        summary={shareableContent?.title}
                        className="px-1"
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
            </div>

            <div className="px-1">
                <EmailShareButton
                    url={shareableContent?.shareUrl}
                    subject={"Checkout SageBuyer Extension"}
                    body={`${shareableContent?.title} - `}
                    className="px-1"
                >
                    <EmailIcon size={32} round />
                </EmailShareButton>
            </div>




        </div>
    )
}

export default SocialIcons;