import React from "react";
import Logo from "../../../components/Logo";

export const Header = (props) => {
  return (
    <>
      <header>
        <nav
          className="bg-blue-50 border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 fixed top-0 w-full z-10"
        >
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a href="." className="flex items-center">
              <Logo />
            </a>

            <div className="flex items-center">
              <a
                href="/onboarding"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Take a Survey
              </a>
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
          </div>
        </nav>
      </header >

      <section className="bg-blue-50 dark:bg-gray-900 pt-16 md:pt-24">
        <div className="container mx-auto px-4 py-8 lg:py-16">
          <div className="grid lg:grid-cols-12 gap-9">
            <div className="lg:col-span-8 lg:col-start-2  text-justify">
              <h1 className="mb-8 text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold leading-tight dark:text-white">
                Buy from People You Trust
              </h1>
              <p className="mb-6 text-base md:text-lg lg:text-xl font-light text-gray-500 dark:text-gray-400">
                SageBuyer helps you align your online shopping with your personal values. Your money is your voice, and
                with SageBuyer, you can speak volumes. By integrating AI-driven insights into a company's approach to
                sustainability and responsibility, we enable you to support businesses that practice what you care about.
              </p>

              <div className="mt-4 font-light text-gray-500 text-base md:text-lg dark:text-gray-400">
                <p className="mb-6">
                  SageBuyer's browser extension seamlessly integrates into your online shopping routine, alerting you
                  when items in your cart don't align with your personal values. With SageBuyer, every purchase decision
                  is an informed decision.
                </p>
                <div className="flex justify-start">
                  <a href="/extension"
                    target="_blank" rel="noreferrer"
                    className="inline-flex items-center rounded-full bg-red-500 px-6 py-3 text-sm font-medium
                text-white shadow-md transition duration-150 ease-in-out hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    <span>Download the extension</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 ml-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="hidden ">
              {/* <img
                src="https://images.pexels.com/photos/4482900/pexels-photo-4482900.jpeg"
                className="rounded-lg shadow-lg object-cover w-full h-full"
                alt="sustainable shopping hero"
              /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
