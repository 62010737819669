import axios from "axios";
import { navigateTo } from "../navigationService";


const axiosConfig = {
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const api = axios.create(axiosConfig);

api.interceptors.request.use((config) => {
  let userId = localStorage.getItem("user_id");

  if (userId) {
    config.headers['x-user-id'] = userId;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Use the navigation service to navigate
      navigateTo('/');
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 400)) {
      // Use the navigation service to navigate
      navigateTo('/home');
    }
    return Promise.reject(error);
  }
);

export default api;
