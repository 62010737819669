import React, { useState } from "react";
import PersonalizeCookies from "./PersonalizeCookies";

function CookieBanner() {
  const [personalize, setPersonalize] = useState();

  function hideBanner() {
    document.getElementById("cookies-corner-modal").style.display = "none";
    localStorage.setItem("cookie", true);
  }

  return (
    <>
      {!JSON.parse(localStorage.getItem("cookie")) && (
        <div
          id="cookies-corner-modal"
          style={{ position: "fixed", bottom: 0 }}
          aria-hidden="false"
          tabIndex="-1"
        >
          <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              <div className="p-5 text-sm font-light text-gray-500 dark:text-gray-400">
                <p className="mb-2">
                  We use cookies, including third party cookies, for operational
                  purposes, statistical analyses, to personalize your
                  experience, provide you with targeted content tailored to your
                  interests and to analyze the performance of our advertising
                  campaigns.
                </p>
                <p>
                  To find out more about the types of cookies, as well as who
                  sends them on our website, please visit our dedicated guide to{" "}
                  <a
                    href="#"
                    className="font-normal text-gray-900 hover:underline dark:text-white"
                  >
                    managing cookies
                  </a>
                  .
                </p>
              </div>
              <div className="justify-between items-center p-6 pt-0 space-y-4 sm:flex sm:space-y-0">
                <button
                  onClick={() => {
                    setPersonalize(true);
                    localStorage.setItem("cookie-pref", true);
                    hideBanner();
                  }}
                  id="close-modal"
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Personalize my choices
                </button>
                <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
                  <button
                    onClick={hideBanner}
                    id="block-cookies"
                    type="button"
                    className="py-2 px-4 w-full text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Reject all
                  </button>
                  <button
                    onClick={hideBanner}
                    id="accept-cookies"
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Accept all
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(personalize || JSON.parse(localStorage.getItem("cookie-pref"))) && (
        <PersonalizeCookies />
      )}
    </>
  );
}

export default CookieBanner;
