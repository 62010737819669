import Logo from "../assets/Icon.png";

function SageBanner() {
  return (
    <div className="flex flex-1 items-center justify-center bg-blue-600 p-8">
      <div className="text-white sm:mx-auto sm:w-full sm:max-w-md">
        <div
          className="text-center flex-col "
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="ESG Logo" style={{ width: "50%" }} />
          <div>
            <h3 className="mt-2 text-3xl font-extrabold leading-9 drop-shadow-xl">
              Buy from People You Trust
            </h3>
            <p className="mt-2">
              Join the movement of conscious shopping. With our browser
              extension, aligning your purchases with your values is just a
              click away.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SageBanner;
