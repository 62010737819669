import { Header } from "./components/Header";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import SmoothScroll from "smooth-scroll";
import { Footer } from "./components/Footer";
import "./LandingPage.css";
import QRCodeCard from "./QrCodeCard";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const LandingPage = () => {
  return (
    <div>
      <div id="home">
        <Header />
      </div>
      <About />
      <div id="contact">
        <Contact />
      </div>
      <Footer />
      <QRCodeCard></QRCodeCard>
    </div>
  );
};

export default LandingPage;
