import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../axios/axios';
import Notification from '../../components/Nofications/Notification';
import Opinion from '../../components/Opinion';
import './custom-scrollbar.css';

const topics = [
    { id: 'climate_change', title: 'Climate Change' },
    { id: 'air_pollution', title: 'Air Pollution' },
    { id: 'water_pollution', title: 'Water Pollution' },
    { id: 'deforestation', title: 'Deforestation' },
    { id: 'loss_of_biodiversity', title: 'Loss of Biodiversity' },
    { id: 'ocean_acidification', title: 'Ocean Acidification' },
    { id: 'soil_degradation', title: 'Soil Degradation' },
    { id: 'waste_management', title: 'Waste Management' },
    { id: 'plastic_pollution', title: 'Plastic Pollution' },
    { id: 'overfishing', title: 'Overfishing' },
    { id: 'renewable_energy', title: 'Renewable Energy' },
    { id: 'carbon_footprint', title: 'Carbon Footprint' },
    { id: 'greenhouse_gas_emissions', title: 'Greenhouse Gas Emissions' },
    { id: 'environmental_justice', title: 'Environmental Justice' },
    { id: 'sustainable_agriculture', title: 'Sustainable Agriculture' },
    { id: 'human_rights', title: 'Human Rights' },
    { id: 'labor_rights', title: 'Labor Rights' },
    { id: 'gender_equality', title: 'Gender Equality' },
    { id: 'racial_equality', title: 'Racial Equality' },
    { id: 'child_labor', title: 'Child Labor' },
    { id: 'fair_trade', title: 'Fair Trade Practices' },
    { id: 'health_safety_standards', title: 'Health and Safety Standards' },
    { id: 'access_to_education', title: 'Access to Education' },
    { id: 'access_to_healthcare', title: 'Access to Healthcare' },
    { id: 'animal_rights', title: 'Animal Rights' },
    { id: 'indigenous_rights', title: 'Indigenous Rights' },
    { id: 'poverty_alleviation', title: 'Poverty Alleviation' },
    { id: 'affordable_housing', title: 'Affordable Housing' },
    { id: 'social_inclusion', title: 'Social Inclusion' },
    { id: 'digital_divide', title: 'Digital Divide' },
    { id: 'christian_values', title: 'Christian Values' },
    { id: 'data_privacy', title: 'Data Privacy and Security' },
    { id: 'energy_independence', title: 'Energy Independence' },
    { id: 'environmental_stewardship', title: 'Environmental Stewardship' },
    { id: 'ethical_sourcing', title: 'Ethical Sourcing of Materials' },
    { id: 'free_market', title: 'Free Market Capitalism' },
    { id: 'immigration_control', title: 'Immigration Control' },
    { id: 'lower_taxes', title: 'Lower Taxes and Economic Freedom' },
    { id: 'made_in_america', title: 'Made in America' },
    { id: 'made_in_china', title: 'Made in China' },
    { id: 'national_security', title: 'National Security and Defense' },
    { id: 'anti_tech_censorship', title: 'Opposition to Big Tech Censorship' },
    { id: 'anti_globalism', title: 'Opposition to Globalism' },
    { id: 'pro_choice', title: 'Pro-Choice Advocacy' },
    { id: 'pro_life', title: 'Pro-Life Advocacy' },
    { id: 'second_amendment', title: 'Second Amendment Rights' },
    { id: 'law_enforcement', title: 'Support for Law Enforcement' },
    { id: 'veteran_support', title: 'Support for Veterans' },
    { id: 'traditional_family', title: 'Traditional Family Values' },
    { id: 'water_conservation', title: 'Water Conservation' },
].sort((a, b) => a.title.localeCompare(b.title));


const TopicSelection = ({ onSubmit }) => {
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);
    const navigate = useNavigate();

    const handleTopicToggle = (topicId) => {
        setSelectedTopics((prev) => {
            if (prev.includes(topicId)) {
                return prev.filter((id) => id !== topicId);
            } else {
                if (prev.length >= 3) {
                    setNotificationMessage('You can only select up to 3 topics.');
                    setIsSuccess(false);
                    setShowNotification(true);
                    return prev;
                }
                return [...prev, topicId];
            }
        });
    };


    const handleSubmit = () => {
        const user_id = localStorage.getItem("user_id");
        const topicsData = { user_id, topics: selectedTopics };

        api.post("/topics", topicsData)
            .then((response) => {
                if (response.status === 200) {
                    setNotificationMessage("Topics saved successfully!");
                    setIsSuccess(true);
                    setShowNotification(true);
                    setTimeout(() => {
                        onSubmit();
                    }, 1000);
                } else {
                    throw new Error("Topics not saved. Please try again.");
                }
            })
            .catch((error) => {
                console.error("Error saving topics:", error);
                setNotificationMessage("Error saving topics. Please try again.");
                setIsSuccess(false);
                setShowNotification(true);
            });
    };

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    // Function to distribute topics across columns
    const distributeTopics = () => {
        const columns = 3;
        const itemsPerColumn = Math.ceil(topics.length / columns);
        return topics.reduce((acc, topic, index) => {
            const columnIndex = index % columns;
            if (!acc[columnIndex]) {
                acc[columnIndex] = [];
            }
            acc[columnIndex].push(topic);
            return acc;
        }, Array(columns).fill().map(() => []));
    };

    const distributedTopics = distributeTopics();

    return (
        <div className="max-w-4xl mx-auto pt-6 bg-white rounded-lg relative h-screen">
            {showNotification && (
                <Notification
                    isSuccess={isSuccess}
                    onClose={handleCloseNotification}
                    msg={notificationMessage}
                />
            )}
            <div className='flex flex-col justify-around px-10'>
                <div>
                    <h1 className="text-3xl font-bold mb-2 text-center">Select Your Topics of Interest</h1>
                    <p className="text-lg mb-6 text-center text-slate-700">
                        We expect to add new topics over time. Can you help us by picking the three you would most like to see us work on next.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-h-[calc(100vh-420px)] overflow-y-auto px-4 rounded-lg shadow-md custom-scrollbar mb-6">
                        {distributedTopics.map((column, columnIndex) => (
                            <div key={columnIndex} className="flex flex-col gap-4">
                                {column.map((topic) => (
                                    <div
                                        key={topic.id}
                                        className={`p-4 border rounded-lg cursor-pointer transition-colors ${selectedTopics.includes(topic.id)
                                            ? 'bg-blue-100 border-blue-500'
                                            : 'bg-white hover:bg-gray-100'
                                            }`}
                                        onClick={() => handleTopicToggle(topic.id)}
                                    >
                                        <h3 className="font-semibold">{topic.title}</h3>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col mx-">
                    <h1 className="text-lg text-slate-700 my-4 mb-4">
                        What else would you be concerned about when buying a product?
                    </h1>
                    <textarea
                        className="w-full p-2 border rounded"
                        placeholder="Your answer here..."
                        rows="2"
                    ></textarea>
                </div>
                <div className="flex flex-col items-center ">
                    <button
                        className={`text-white px-6 py-3 rounded-lg w-full my-4 transition-colors ${selectedTopics.length > 0
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-gray-400 cursor-not-allowed'
                            }`}
                        onClick={handleSubmit}
                        disabled={selectedTopics.length === 0}
                    >
                        Submit
                    </button>
                    <p className="text-xs text-gray-500 pb-3 text-center">
                        By clicking Submit, I agree to the <a href="/privacy-policy" className="text-blue-600 hover:underline">privacy policy</a> and the <a href="/terms-&-conditions" className="text-blue-600 hover:underline">terms and conditions</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TopicSelection;