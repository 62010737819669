import React from 'react';

function DebuggerWindow({ object }) {
    return (
        <div className="fixed bottom-0 right-0 bg-gray-200 p-4 m-4 rounded shadow overflow-scroll  max-h-3xl">
            <h3 className="text-lg font-bold mb-2">Debugger Window</h3>
            <pre>{JSON.stringify(object, null, 2)}</pre>
        </div>
    );
}

export default DebuggerWindow;