import React from "react";
import SagebuyerLogo from "../assets/Icon.png";
function Logo() {
  return (
    <>
      <img
        src={SagebuyerLogo}
        className="h-16"
        alt="SageBuyer Logo"
        style={{ marginRight: "10px" }}
      />
      <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
        SageBuyer
      </span>
    </>
  );
}

export default Logo;
