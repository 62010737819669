/*global chrome*/
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
export default function Navbar() {
  const logout = () => {
    const extensionId = document.body.dataset.sageBuyerId;
    if (extensionId) {
      chrome?.runtime?.sendMessage(
        extensionId,
        { type: "LOGOUT", user_id: null },
        function (response) {
          console.log("Logging out of Extension", response);
        }
      );
    }

    localStorage.clear();
    window.location.href = "/";
  };
  const navigate = useNavigate();
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <Logo />
          {/* <img src={Logo} className="h-8" alt="SageBuyer Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            SageBuyer
          </span> */}
        </a>
        <button
          onClick={() => navigate("/home")}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

      </div>
    </nav>
  );
}
