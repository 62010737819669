import React, { useState, useEffect } from "react";

import api from "../../axios/axios";
import SocialIcons from "../SocialIcons";

const SentenceWithCompanyName = ({ sentenceParts, companyName, areas }) => {
  return (
    <div className="text-base sm:text-xl leading-7 sm:leading-8">
      {sentenceParts.map((part, index) => (
        <React.Fragment key={index}>
          {part === companyName ? (
            <span className="font-semibold underline">{part}</span>
          ) : (
            <span>{part}</span>
          )}
          {index < sentenceParts.length - 1 && " "}
        </React.Fragment>
      ))}
      {areas && areas.length > 0 && (
        <ul className="list-disc pl-4 mt-2">
          {areas.map((area, index) => (
            <li key={index}>{area}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
const Explanation = () => {

  const [generatedSentences, setGeneratedSentences] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [shareableContent, setShareableContent] = useState({});
  const [confidenceColor, setConfidenceColor] = useState('');
  const [recommendedBrands, setRecommendedBrands] = useState({});
  const [hasUserIdBool, setHasUserIdBool] = useState({});
  const [colorClasses, setColorClasses] = useState('bg-blue-100 text-blue-800');

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const params = new URLSearchParams(parsedUrl.search);
    const confidenceColorParam = params.get("confidenceColor");
    const companyIdParam = params.get("companyId");
    const hasUserId = params.get("hasUserId") === 'true';
    setHasUserIdBool(hasUserId);
    const userId = localStorage.getItem('user_id');
    const brandName = params.get("brandName");
    const productName = params.get("productName");

    const confidenceColor = confidenceColorParam ? confidenceColorParam.toLowerCase() : '';
    const companyId = companyIdParam ? companyIdParam : 'null';

    setConfidenceColor(confidenceColor);

    const getColorClasses = (color) => {
      switch (color) {
        case 'red':
          return 'bg-red-100 text-red-800';
        case 'green':
          return 'bg-green-100 text-green-800';
        case 'yellow':
          return 'bg-yellow-100 text-yellow-800';
        default:
          return 'bg-blue-100 text-blue-800';
      }
    };

    setColorClasses(getColorClasses(confidenceColor));

    const generateBrandRecommendations = (productName, brandName) => {
      console.log("Generating brand recommendations for", productName, brandName);
      api
        .get(`/recommendations/${brandName}/${productName}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            setRecommendedBrands(response.data);
          }
        })
        .catch((error) => {
          console.error(
            "Brand Recommendations Information for the company not found",
            error
          );
        });
    };

    if (confidenceColor === 'red' || confidenceColor === 'yellow') {
      generateBrandRecommendations(productName, brandName);
    } else {
      setRecommendedBrands({});
    }

    let tempShareableContent = {};

    api
      .get(`/explanation/${companyId}/${confidenceColor}/${userId ? true : false}/${brandName}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setGeneratedSentences(response.data);
          tempShareableContent.shareUrl = window.location.href;
          tempShareableContent.generatedSentences = response.data;
          setShareableContent(tempShareableContent);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setGeneratedSentences(null);
          tempShareableContent.shareUrl = window.location.href;
          setShareableContent(tempShareableContent);
          return;
        }
        console.error("Explanation Information for the company not found", error);
      });
  }, []);

  return (
    <div className="bg-white px-4 py-8 sm:px-6 sm:py-16 lg:px-8 lg:py-32">
      <div
        className="mx-auto max-w-3xl text-base leading-7 text-gray-700"
        id="terms-and-conditions"
      >
        <div className="flex flex-col items-center sm:flex-row sm:items-baseline sm:justify-between gap-4 sm:gap-10">
          <div className="order-2 sm:order-1">
            {shareableContent &&
              <SocialIcons shareableContent={shareableContent} />}
          </div>
          <h1 className="order-1 sm:order-2 mt-2 text-2xl sm:text-3xl text-center font-bold tracking-tight text-gray-900 lg:text-4xl">
            Explanation
          </h1>
          <a
            href="/onboarding"
            className="order-3 inline-block rounded-full bg-red-500 px-4 sm:px-7 py-2 sm:py-2.5 text-xs sm:text-sm font-medium leading-normal text-white shadow-slate-800 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
          >
            Take a survey
          </a>
        </div>

        {generatedSentences && (
          <div className={`mt-6 shadow-lg rounded-lg p-4 sm:p-6 ${colorClasses}`}>
            <SentenceWithCompanyName
              sentenceParts={generatedSentences.sentenceParts}
              companyName={generatedSentences.companyName}
              areas={generatedSentences.areas}
            />
          </div>
        )}


        {recommendedBrands && recommendedBrands.length > 0 && (
          <div className="mt-6 shadow-lg rounded-lg bg-blue-100 p-4 sm:p-6 text-blue-800">
            <p className="text-base sm:text-xl leading-7 sm:leading-8 font-semibold ">
              Here are some brand(s) you might want to consider:
            </p>
            <ul className="mt-4 space-y-2">

              {recommendedBrands.map((brand, index) => (
                <li key={index} className="flex justify-between items-center bg-white p-2 rounded-md shadow-sm">
                  <span className="font-medium flex flex-col">
                    {brand?.brand_name}
                    {brand.recommendation && <span className="text-sm text-gray-600 font-thin">{brand?.recommendation}</span>}
                  </span>
                  <span className="text-sm text-gray-600">{brand?.company_name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!recommendedBrands.length && confidenceColor !== 'green' && (
          <div className="mt-6 shadow-lg rounded-lg bg-blue-100 p-4 sm:p-6 text-blue-800">
            <p className="text-base sm:text-xl leading-7 sm:leading-8  ">
              Generating brand recommendations for you ...
            </p>
            <div
              className="flex m-auto mt-6 h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
              <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span>
            </div>

          </div>)
        }

        <p className="mt-6 text-base sm:text-xl leading-7 sm:leading-8">
          We rate companies based on an assessment of their policy and record in
          twelve key areas. The assessments are based on the company's public
          statements (SEC filings, Sustainability and/or Diversity Statements,
          Corporate Governance positions) as well as reports in the media on the
          company.
        </p>

        <p className="my-6 text-base sm:text-xl leading-7 sm:leading-8">
          But we understand that values are personal, so you can also define
          what matters most to you. Set your preferences now and shop confidently,
          knowing that your choices are in sync with your beliefs
        </p>

        <div className="flex justify-center">
          <a
            href="/onboarding"
            className="inline-block rounded-full bg-red-500 px-4 sm:px-7 py-2 text-xs sm:text-sm font-medium mb-5 leading-normal text-white shadow-slate-800 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none"
          >
            <span>Click here to complete a short survey</span>
          </a>
        </div>

        <p className="text-base sm:text-xl leading-7 sm:leading-8">
          Our company has been a pioneer in the ESG (Environmental, Social, and Governance)
          space, committed to revolutionizing how these factors are measured and analyzed.
          We balance traditional methods relying on human experts with cutting-edge
          Artificial Intelligence and Machine Learning (AI/ML) technologies. The AI/ML
          models we've developed have been in use at leading financial institutions for several years.
        </p>

        <br />

        <p className="text-base sm:text-xl leading-7 sm:leading-8">
          SageBuyer's browser extension is the ultimate tool for the conscious shopper.
          It seamlessly integrates into your online shopping routine, alerting you when items
          in your cart don't align with your personal values. With SageBuyer, every purchase
          decision is an informed decision
        </p>

        <h3 className="flex justify-center mt-4 text-sm sm:text-base text-gray-600">U.S. Patent No.12106316</h3>
      </div>
    </div>
  );
};

export default Explanation;
