import React, { useRef } from 'react';
import { detect } from 'detect-browser';
import EnableExtensionGif from '../assets/enable_extension.gif';
import ManageExtensionsIcon from '../assets/extension .svg';
import ExtensionToggle from '../assets/toggle-on.png';
import SagebuyerLogo from "../assets/Icon.png";

const EnableExtensionPage = () => {
    const browser = detect();
    const userAgent = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();

    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isMac = /mac/.test(platform);
    const isSafari = (isIOS || (isMac && browser.name === 'safari'));
    const gifRef = useRef(null);

    if (!isSafari) {
        return <h1>You're not on Safari</h1>;
    }

    const handleGifClick = () => {
        if (gifRef.current) {
            gifRef.current.src = '';
            gifRef.current.src = EnableExtensionGif;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <div className="max-w-lg w-full bg-white p-6 rounded-lg shadow-md">
                <div className="flex flex-col items-center">
                    <div className="bg-blue-400 rounded-xl p-1 mb-1 relative flex items-center justify-center">
                        <img
                            ref={gifRef}
                            src={EnableExtensionGif}
                            alt="Instructional GIF"
                            className="object-cover w-80 bg-orange-100 rounded-xl"
                            onClick={handleGifClick}
                        />
                    </div>

                    <button
                        onClick={handleGifClick}
                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-all duration-300"
                    >
                        Replay
                    </button>

                    <h1 className="text-xl font-semibold text-center mb-4 mt-6">
                        First we need you to enable the extension for Safari
                    </h1>

                    <ol className="list-decimal text-left pl-6 text-gray-700 space-y-2">
                        <li>
                            Tap the <span className='font-bold'><span className="align-sub">ᴬ</span>A</span> in the address bar
                        </li>
                        <li>
                            Tap manage extensions <span className="inline-flex items-center"><img src={ManageExtensionsIcon} alt="Manage Extensions" className="w-5 h-5 ml-1" /></span>
                        </li>
                        <li>
                            Turn on SageBuyer Extension<span className="inline-flex items-center">
                                <img
                                    src={SagebuyerLogo}
                                    className="h-8 pl-2 translate-y-2"
                                    alt="SageBuyer Logo"
                                    style={{ marginRight: "10px" }}
                                />
                                <img src={ExtensionToggle} alt="Extension toggle" className="ml-1 w-9 h-10 translate-y-2" />
                            </span>
                        </li>
                    </ol>
                </div>
            </div>
            {/* <div className="max-w-lg w-full text-xs mt-6 text-center text-gray-500">
                If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <br />
                <a href="https://example.com" className="underline text-blue-600">Manage cookies and learn more</a>
            </div> */}
        </div>
    );
};

export default EnableExtensionPage;
