import React, { useEffect, useState } from "react";
import api from "../../axios/axios";

const AllLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.get("/public/logs/all-logs");
        let logs = response.data;
        logs = logs.map((log) => {
          delete log.request_id;
          //   delete log.timestamp;
          return log;
        });
        setLogs(logs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logs:", error);
        setError("Failed to fetch logs. Please try again.");
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  function getBgColor(status) {
    switch (status) {
      case "failure":
        return "bg-red-100";
      case "success":
        return "bg-green-100";
      default:
        return "bg-white";
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-4">All Logs</h1>
      <div className="grid grid-cols-1 gap-4">
        {logs.map((log, index) => (
          <div
            key={index}
            className={`shadow-md rounded-md p-4 ${getBgColor(log.status)}`}
          >
            <div className="flex justify-between items-center mb-2">
              <span
                className={`text-sm font-semibold ${
                  log.type === "ai_model" ? "text-blue-500" : "text-green-500"
                }`}
              >
                {log.type === "ai_model"
                  ? "AI Model Request"
                  : "Backend Request"}
              </span>
              <span className="text-sm text-gray-500">
                {new Date(log.timestamp).toLocaleString()}
              </span>
            </div>
            <pre className="text-sm text-gray-800 overflow-x-auto">
              {JSON.stringify(log, null, 2)}
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllLogs;
