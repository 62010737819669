import React, { useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaTimes } from 'react-icons/fa';
import SocialIcons from '../../components/SocialIcons';

const QRCodeCard = () => {
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Replace this with your actual QR code generation logic
        const generateQRCode = () => {
            // This is a placeholder. Replace with your actual QR code generation
            return './Sagebuyer_extension_page.png';
        };

        setQrCodeUrl(generateQRCode());
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="fixed bottom-4 left-4 z-50">
            <div className="bg-white rounded-lg shadow-lg p-4 max-w-[200px] transition-all duration-300 hover:scale-105 relative">
                <button
                    onClick={handleDismiss}
                    className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Close"
                >
                    <FaTimes size={16} />
                </button>
                <img
                    src={qrCodeUrl}
                    alt="QR Code"
                    className="w-full h-auto mb-2"
                />
                <p className="text-sm text-center mb-2">Download the extension</p>
                <div className="flex justify-center space-x-4">
                    <SocialIcons shareableContent={{ shareUrl: 'https://sagebuyer.ai/extension', title: "SageBuyer Extension" }} />
                </div>
            </div>
        </div>
    );
};

export default QRCodeCard;