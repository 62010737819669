import { Footer } from "./LandingPage/components/Footer";
import Navbar from "./NavBar";
import React, { useEffect, useState } from "react";
import api from "../axios/axios";
import { questions } from "../constants/defaultKeys";
import DownloadIcon from "../assets/icons/download.svg";
import editIcon from "../assets/icons/edit.svg"
import { navigateTo } from "../navigationService";
export default function HomePage() {
  const [userValues, setUserValues] = useState(null);
  const [importantValues, setImportantValues] = useState(null);
  const [unImportantValues, setUnImportantValues] = useState(null);

  const colorMap = {
    3: { labelColor: "bg-green-300", textColor: "text-green-900" },
    2: { labelColor: "bg-green-100", textColor: "text-green-800" },
    1: { labelColor: "bg-yellow-200", textColor: "text-yellow-800" },
    "-1": { labelColor: "bg-orange-100", textColor: "text-orange-700" },
    "-2": { labelColor: "bg-red-100", textColor: "text-red-700" },
    "-3": { labelColor: "bg-red-200", textColor: "text-red-900" },
  };

  useEffect(() => {
    // const userId = localStorage.getItem("user_id");
    // if (!userId) {
    //   navigateTo("/");
    // }
    // api
    //   .get(`/user-choices/${userId}`)
    //   .then((response) => {
    //     if (response?.data) {
    //       setUserValues(response.data?.user_choices);

    //       const importantQuestions = [];
    //       const unImportantQuestions = [];

    //       Object.entries(response.data).forEach(
    //         ([key, value]) => {
    //           const question = questions.find((q) => q.keyString === key);
    //           if (question) {
    //             if (value > 0 && value <= 3) {
    //               question.value = value;
    //               importantQuestions.push(question);
    //             } else if (value < 0 && value >= -3) {
    //               question.value = value;
    //               unImportantQuestions.push(question);
    //             }
    //           }
    //         }
    //       );
    //       setImportantValues(importantQuestions);
    //       setUnImportantValues(unImportantQuestions);
    //     } else {
    //       setUserValues(null);
    //     }
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow flex flex-col px-3 py-6 sm:px-6 lg:px-12">
        <div className="max-w-4xl w-full mx-auto">
          <div className="bg-blue-100 border mb-6 rounded-xl border-blue-500 text-blue-700 px-4 py-3" role="alert">
            <p className="font-bold">Your Feedback Matters!</p>
            <p className="text-sm">Are we missing a brand or company? Do you have questions about how companies are scored? Do you have ideas for how we can make this more useful to you? Any and all feedback welcomed!</p>
            <div className="mt-4">
              <a href="/feedback" className="inline-block bg-blue-200 text-blue-800 font-bold py-2 px-4 rounded hover:bg-blue-300 transition-colors">Send Feedback</a>
            </div>
          </div>
        </div>

        <div className="flex-grow flex flex-col items-center">
          <a
            target="_blank"
            rel="noreferrer"
            href="/extension"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <img
              src={DownloadIcon}
              alt="Download"
              className="h-5 inline mr-2"
            />
            Download Extension
          </a>
          <a
            rel="noreferrer"
            href="/onboarding"
            className="flex justify-center mt-3   rounded-md border border-slate-500  px-8 py-3 text-sm font-medium text-gray-800 shadow-sm hover:border-blue-700  hover:text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-2 sm:mb-0"
          >
            <img
              src={editIcon}
              alt="Edit"
              className="h-5 inline mr-1 "
            />
            Take the survey again
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
