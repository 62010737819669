import React from "react";
import backArrow from "../../assets/icons/left-arrow.svg";

function PrivacyPolicy() {
  const baseURL = "privacy-policy";
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div
        className="mx-auto max-w-3xl text-base leading-7 text-gray-700"
        id="privacy-policy"
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={backArrow}
            alt="back"
            className="h-6 cursor-pointer"
            onClick={() => window.history.back()}
          />
          <p className="text-base font-semibold leading-7 text-indigo-600">
            Last updated August 30, 2024
          </p>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          SageBuyer Privacy Policy
        </h1>

        <div className="mt-10 max-w-2xl">
          <div id="introduction">
            <a
              href={`${baseURL}#introduction`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              1. Introduction
            </a>
            <p className="mt-6">
              We respect your privacy and do not collect any personally identifiable information (PII). This policy explains how we use cookies and share information.
            </p>
          </div>

          <div id="information-collection" className="mt-8">
            <a
              href={`${baseURL}#information-collection`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              2. Information Collection
            </a>
            <p className="mt-6">
              We do not collect any PII. We use cookies to gather information about your preferences and activities on our website.            </p>
          </div>

          <div id="use-of-cookies" className="mt-8">
            <a
              href={`${baseURL}#use-of-cookies`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              3. Use of Cookies
            </a>
            <p className="mt-6">
              Cookies help us understand user preferences and provide relevant advertisements from our partners. We share aggregated, non-identifiable data with advertisers to enhance their targeted advertising.
            </p>
          </div>

          <div id="data-sharing" className="mt-8">
            <a
              href={`${baseURL}#data-sharing`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              4. Data Sharing
            </a>
            <p className="mt-6">
              We share only aggregated information derived from cookies with our advertising partners. This data is used solely to tailor advertisements based on user preferences.            </p>
          </div>

          <div id="your-rights" className="mt-8">
            <a
              href={`${baseURL}#your-rights`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              5. Your Rights
            </a>
            <p className="mt-6">
              You can manage your cookie preferences through your browser settings. By continuing to use our site, you consent to the use of cookies as described in this policy.
            </p>
          </div>

          <div id="changes-to-this-policy" className="mt-8">
            <a
              href={`${baseURL}#changes-to-this-policy`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              6. Changes to This Policy
            </a>
            <p className="mt-6">
              We may update this policy periodically. Changes will be posted with an updated effective date.
            </p>
          </div>

          <div id="contact-us" className="mt-8">
            <a
              href={`${baseURL}#contact-us`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              7. Contact Us 
            </a>
            <p className="mt-6">
              For any questions, please contact us at <a className="text-blue-700" href="mailto:info@sagebuyer.ai">info@sagebuyer.ai</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;