/*global chrome*/

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Profile from "./pages/Profile/Profile";
import Onboarding from "./pages/Onboarding/Onboarding";
import HomePage from "./pages/HomePage";
import ExtensionApp from "./pages/Extension";
import LandingPage from "./pages/LandingPage/LandingPage";
import Logs from "./pages/Logs/Logs";
import CookieBanner from "./components/Cookies/Banner";
import TC from "./components/Legal/TC";
import PrivacyPolicy from "./components/Legal/Privacypolicy";
import NotFound from "./components/NotFound";
import Explanation from "./components/ExplanationPage/Explanation";
import FeedbackPage from "./pages/Feedback/FeedbackPage";
import { setNavigate } from './navigationService';
import AllLogs from "./pages/Logs/AllLogs";
import TopicSelection from "./pages/Welcome/TopicSelection";
import EnableExtensionPage from "./pages/EnableExtensionPage";

const AuthHandler = () => {
  const [userState, setUserState] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("sagebuyer_token");
    setUserState(token ?? null);
  }, []);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-&-conditions" element={<TC />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/explanation" element={<Explanation />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/extension" element={<ExtensionApp />} />

          <Route path="/logs/all" element={<AllLogs />} />
          <Route path="/logs" element={<Logs />} />
          <Route
            path="/profile"
            element={
              <Profile setUserState={setUserState} username={userState} />
            }
          />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/enableextension" element={<EnableExtensionPage />} />
          <Route path="/topics" element={<TopicSelection />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <CookieBanner />
    </>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <AuthHandler />
      </Router>
    </div>
  );
}

export default App;
