/*global chrome*/
import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import api from '../../axios/axios';
import questions from './questions';
import Notification from '../../components/Nofications/Notification';
import DebuggerWindow from '../Miscelleneous/DebuggerWindow';

const FeedbackButton = ({ icon: Icon, label, isSelected, onClick, type }) => (
    <button
        className={`flex flex-col items-center justify-center rounded-lg px-3 py-2 m-1 transition-colors w-28
            ${isSelected
                ? type === 'absolutely'
                    ? 'bg-green-600 text-white'
                    : type === 'ok'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-600 text-white'
                : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
            }`}
        onClick={onClick}
    >
        <Icon
            className={`${isSelected
                ? 'text-white'
                : type === 'absolutely'
                    ? 'text-green-500'
                    : type === 'ok'
                        ? 'text-blue-500'
                        : 'text-gray-500'
                } mb-1`}
            size={20}
        />
        <span className="text-sm">{label}</span>
    </button>
);

const PoliticsButton = ({ label, isSelected, onClick, type }) => (
    <button
        className={`flex items-center justify-center rounded-lg px-3 py-2 m-1 transition-colors w-28
            ${isSelected
                ? type === 'left'
                    ? 'bg-blue-600 text-white'
                    : type === 'nope'
                        ? 'bg-gray-600 text-white'
                        : 'bg-red-600 text-white'
                : type === 'left'
                    ? 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                    : type === 'nope'
                        ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        : 'bg-red-100 text-red-800 hover:bg-red-200'
            }`}
        onClick={onClick}
    >
        <span className="text-sm">{label}</span>
    </button>
);

const EthicsQuestionnaire = ({ onSubmit }) => {
    const [questionsArray, setQuestionsArray] = useState([]);
    const [preferences, setPreferences] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const politicsQuestion = questions.find(q => q.id === 'politics');
        const otherQuestions = questions.filter(q => q.id !== 'politics');
        const shuffledQuestions = shuffleArray(otherQuestions);
        setQuestionsArray([...shuffledQuestions, politicsQuestion]);

        // Initialize preferences with default values
        const defaultPreferences = questions.reduce((acc, q) => {
            acc[q.id] = q.id === 'politics' ? 0 : 1;
            return acc;
        }, {});
        setPreferences(defaultPreferences);
    }, []);

    useEffect(() => {
        validatePreferences();
    }, [preferences]);

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handlePreferenceChange = (questionId, value) => {
        setPreferences(prev => {
            const newPreferences = { ...prev, [questionId]: value };
            if (questionId !== 'politics') {
                const absolutelyCount = Object.entries(newPreferences)
                    .filter(([key, val]) => key !== 'politics' && val === 2).length;
                const notSoMuchCount = Object.entries(newPreferences)
                    .filter(([key, val]) => key !== 'politics' && val === 0).length;

                if ((absolutelyCount > 3 || notSoMuchCount > 3) && value !== 1) {
                    setShowNotification(true);
                    setNotificationMessage("You can only choose up to three 'Absolutely' or 'Not so much' options.");
                    setIsSuccess(false);
                    return prev;
                }
            }
            return newPreferences;
        });
    };

    const validatePreferences = () => {
        const hasAbsolutelyOrNotSoMuch = Object.entries(preferences)
            .some(([key, value]) => key !== 'politics' && (value === 2 || value === 0));
        setIsValid(hasAbsolutelyOrNotSoMuch);
    };

    const renderRatingButtons = (questionId) => {
        const value = preferences[questionId];

        if (questionId === 'politics') {
            return (
                <div className="flex space-x-2">
                    <PoliticsButton
                        label="Left"
                        isSelected={value === 2}
                        onClick={() => handlePreferenceChange(questionId, 2)}
                        type="left"
                    />
                    <PoliticsButton
                        label="Nope"
                        isSelected={value === 0}
                        onClick={() => handlePreferenceChange(questionId, 0)}
                        type="nope"
                    />
                    <PoliticsButton
                        label="Right"
                        isSelected={value === -2}
                        onClick={() => handlePreferenceChange(questionId, -2)}
                        type="right"
                    />
                </div>
            );
        }

        return (
            <div className="flex space-x-2">
                <FeedbackButton
                    icon={Star}
                    label="Absolutely"
                    isSelected={value === 2}
                    onClick={() => handlePreferenceChange(questionId, 2)}
                    type="absolutely"
                />
                <FeedbackButton
                    icon={Star}
                    label="OK"
                    isSelected={value === 1}
                    onClick={() => handlePreferenceChange(questionId, 1)}
                    type="ok"
                />
                <FeedbackButton
                    icon={Star}
                    label="Not so much"
                    isSelected={value === 0}
                    onClick={() => handlePreferenceChange(questionId, 0)}
                    type="not-so-much"
                />
            </div>
        );
    };

    const setUserIdOnExtension = (id) => {
        window.postMessage({ type: 'LOGIN', user_id: id }, '*');
    }



    const handleSubmit = () => {
        const user_id = localStorage.getItem("user_id");
        const preferencesWithUserId = { ...preferences, user_id };

        api.post("/user-choices", preferencesWithUserId)
            .then((response) => {
                if (response.status === 200 && response.data.user_id) {
                    localStorage.setItem("user_id", response.data.user_id);
                    setUserIdOnExtension(response.data.user_id)
                    setNotificationMessage("Preferences saved successfully!");
                    setIsSuccess(true);
                    setShowNotification(true);
                    setTimeout(() => {
                        onSubmit();
                    }, 1000);
                } else {
                    throw new Error("User Choices not saved. Please try again.");
                }
            })
            .catch((error) => {
                console.error("Error saving user choices:", error);
                setNotificationMessage("Error saving preferences. Please try again.");
                setIsSuccess(false);
                setShowNotification(true);
            });
    };

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    return (
        <div className="max-w-4xl mx-auto pt-6 bg-white rounded-lg relative px-10">
            {showNotification && (
                <Notification
                    isSuccess={isSuccess}
                    onClose={handleCloseNotification}
                    msg={notificationMessage}
                />
            )}
            <h1 className="text-3xl font-bold mb-6 text-center">What do you care about?</h1>
            <div className="flex flex-col">
                <p className="text-lg mb-6 text-center text-slate-700">
                    Please select your preferences for each topic. You can choose up to three "Absolutely" and three "Not so much" options.
                </p>
            </div>
            <div className="max-h-[calc(100vh-276px)] overflow-y-auto">
                {questionsArray.map((question) => (
                    <div key={question.id} className="mb-6 p-4 shadow-md rounded-lg">
                        <div className="flex justify-between items-center flex-wrap">
                            <h2 className="text-xl font-semibold mb-2">{question.title}</h2>
                            {renderRatingButtons(question.id)}
                        </div>
                        <p className="mb-4 text-sm text-gray-500">{question.description}</p>
                    </div>
                ))}
            </div>

            <div className="flex flex-col items-center m-4">
                <button
                    className={`text-white px-6 py-3 rounded-lg w-full mb-4 transition-colors ${isValid
                        ? 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-gray-400 cursor-not-allowed'
                        }`}
                    onClick={handleSubmit}
                    disabled={!isValid}
                >
                    Submit
                </button>
                <p className="text-xs text-gray-500 text-center">
                    By clicking Submit, I agree to the <a href="/privacy-policy" className="text-blue-600 hover:underline">privacy policy</a> and the <a href="/terms-&-conditions" className="text-blue-600 hover:underline">terms and conditions</a>
                </p>
                {/* <DebuggerWindow object={preferences} /> */}

            </div>
        </div>
    );
};

export default EthicsQuestionnaire;