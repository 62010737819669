const questions = [
    {
        id: 'pollution',
        title: 'Pollution',
        description: 'Companies should be strictly regulated to minimize pollution and dumping, ensuring clean air, land, and water. By reducing microplastics, preventing extinctions, and stopping deforestation, we can promote greater biodiversity and protect our environment for future generations.'
    },
    {
        id: 'unions',
        title: 'Unions and Jobs',
        description: 'I support unions and oppose right-to-work laws. Companies should be required to provide a living wage, adequate time off for emergencies and family needs, and maintain a safe, healthy workplace environment for all employees.'
    },
    {
        id: 'animalCruelty',
        title: 'Animal Cruelty',
        description: 'Cruelty to animals must be condemned and eradicated, including practices like animal testing. Companies should be regulated to require humane practices, prevent suffering or abuse, and prioritize animal welfare by using alternatives that eliminate cruelty.'
    },
    {
        id: 'discrimination',
        title: 'Discrimination',
        description: 'Companies should eliminate discrimination at all levels. Employment, promotions, board appointments, and supplier selections must be based solely on qualifications, abilities, and merit. Bias against LGBTQI+, BIPOC, individuals with disabilities, neurodiverse individuals, or other disadvantaged groups should be strictly punished.'
    },
    {
        id: 'climate',
        title: 'Climate',
        description: 'Carbon kills. Human activity is creating dangerous changes to the climate. Gasoline, diesel, and coal contribute to air pollution and ocean acidification through vehicle emissions and industrial processes. We must dramatically and rapidly reduce the use of these fuels.'
    },
    {
        id: 'goodNeighbor',
        title: 'Good Neighbor',
        description: 'Companies have a responsibility to give back to society. They should contribute generously to charities, support local communities, hire locally, sponsor events, source from local businesses, and engage in community development projects.'
    },
    {
        id: 'sustainability',
        title: 'Sustainability',
        description: 'Manufacturers must be forced to prioritize sustainability and recycling in all their operations. Products, packaging and shipping materials should minimize waste and encourage reuse. Companies should require their suppliers to adhere to same standards.'
    },
    {
        id: 'equity',
        title: 'Equity',
        description: 'Excessive executive salaries compared to average worker wages are unfair and should be regulated. Companies must promote fairness across their entire compensation structure to ensure equitable treatment of all employees.'
    },
    {
        id: 'harassment',
        title: 'Harassment',
        description: 'Companies must strictly enforce a zero-tolerance policy on sexual harassment, bullying, or any misconduct in the workplace. They must hold all employees accountable and ensure a safe, respectful environment with thorough training, clear reporting channels, and immediate action against violations.'
    },
    {
        id: 'adsAndEthics',
        title: 'Ads and Ethics',
        description: 'Companies must follow the law and ensure their suppliers do as well. Fraud, money laundering, using conflict minerals, and embargo violations are unacceptable. Truth in advertising is also crucial; false claims, misleading studies, and greenwashing are not acceptable.'
    },
    {
        id: 'safety',
        title: 'Safety',
        description: 'Companies must ensure the safety of their products for all consumers. Rigorous testing, attention to consumer feedback, and clear safety instructions are essential to protect everyone who uses their products.'
    },
    {
        id: 'politics',
        title: 'Politics',
        description: 'I choose to support companies that align with my beliefs and values. I prefer to purchase products from businesses that remain neutral or engage in political activities that reflect my values, avoiding those that conflict with my personal views.'
    }
];

export default questions;