import { Footer } from './LandingPage/components/Footer';
import React, { useEffect, useState } from 'react';
import Navbar from './NavBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { storeUrls } from '../constants/storeUrls';
import { detect } from 'detect-browser';

const ExtensionApp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [redirectUrl, setRedirectUrl] = useState('');
    const parsedUrl = new URL(window.location.href);
    const params = new URLSearchParams(parsedUrl.search);
    const test = params.get("test");

    useEffect(() => {
        const detectPlatform = () => {
            const browser = detect();
            const userAgent = navigator.userAgent.toLowerCase();
            const platform = navigator.platform.toLowerCase();

            const isIOS = /iphone|ipad|ipod/.test(userAgent);
            const isMac = /mac/.test(platform);
            if (test === 'true') {
                alert(`Browser: ${browser.name}, Platform: ${platform}, isIOS: ${isIOS}, isMac: ${isMac}`);
            }
            if (isIOS || (isMac && browser.name === 'safari')) {
                return storeUrls.appStore;
            } else if (browser.name === 'chrome') {
                return storeUrls.chromeWebStore;
            } else if (isMac) {
                return storeUrls.macAppStore;
            } else if (browser.name === 'edge-chromium') {
                alert('You can download the extension from the Chrome Web Store. Redirecting you there now...');
                return storeUrls.chromeWebStore;
            }


            return '';
        };

        if (test === 'true') {
            detectPlatform()
            return
        }
        setRedirectUrl(detectPlatform());
    }, []);

    useEffect(() => {
        if (redirectUrl) {
            sessionStorage.setItem('previousPath', location.pathname);
            window.location.replace(redirectUrl);
        }
    }, [redirectUrl, location.pathname]);

    useEffect(() => {
        const previousPath = sessionStorage.getItem('previousPath');
        if (previousPath) {
            sessionStorage.removeItem('previousPath');
            navigate(previousPath, { replace: true });
        }
    }, [navigate]);

    return (
        <div className='flex min-h-screen flex-col'>
            <Navbar />
            <div className="sm:flex-row flex-1 max-w-4xl mx-auto py-6 sm:px-6 lg:px-12">
                <div className="flex flex-col justify-start w-full">
                    <div className='text-center flex flex-col'>
                        <h1 className='text-center font-extrabold mb-10'>Download Our Extension</h1>
                        <p className='p-6'>If you are not redirected automatically, please choose your platform below:</p>
                        <div className="flex flex-col justify-around items-center h-full">
                            <a href={storeUrls.appStore} type="button" className="flex items-center justify-center w-48 mt-3 text-white bg-black h-14 rounded-xl">
                                <div className="mr-3">
                                    <svg viewBox="0 0 384 512" width="30">
                                        <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                        </path>
                                    </svg>
                                </div>
                                <div>
                                    <div className="text-xs">
                                        Download on the
                                    </div>
                                    <div className="-mt-1 font-sans text-xl font-semibold">
                                        App Store
                                    </div>
                                </div>
                            </a>
                            <a href={storeUrls.chromeWebStore} type="button" className="flex cursor-pointer items-center justify-center mt-3 text-white bg-black w-72 h-16 rounded-xl">
                                <div className="mr-3">
                                    <img src='./download_icons/Download_on_the_Chrome.png' className='w-11 h-11' alt="Chrome Download Icon"></img>
                                </div>
                                <div>
                                    <div className="text-xs">
                                        Download on the
                                    </div>
                                    <div className="-mt-1 font-sans text-2xl font-semibold">
                                        Chrome Web Store
                                    </div>
                                </div>
                            </a>
                            <a href={storeUrls.macAppStore} type="button" className="flex cursor-pointer items-center justify-center mt-3 text-white bg-black w-60 h-14 rounded-xl">
                                <div className="mr-3">
                                    <svg viewBox="0 0 384 512" width="30">
                                        <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                        </path>
                                    </svg>
                                </div>
                                <div>
                                    <div className="text-xs">
                                        Download on the
                                    </div>
                                    <div className="-mt-1 font-sans text-2xl font-semibold">
                                        Mac App Store
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ExtensionApp;
