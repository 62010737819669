import React from "react";
import backArrow from "../../assets/icons/left-arrow.svg";

// Terms & Conditions
function TC() {
  const baseURL = "terms-&-conditions";
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div
        className="mx-auto max-w-3xl text-base leading-7 text-gray-700"
        id="terms-and-conditions"
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={backArrow}
            alt="back"
            className="h-6 cursor-pointer"
            onClick={() => (window.location.href = "/")}
          />
          <p className="block text-base font-semibold leading-7 text-indigo-600">
            Last updated February 26, 2024
          </p>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Terms and Conditions
        </h1>
        <p className="mt-6 text-xl leading-8">
          These Terms and Conditions (the "Agreement") are a contract between you and ESG-RATE, Inc. (“ESG-RATE”) and govern your access to and use of the SageBuyer mobile application (the "SageBuyer Mobile App"), the SageBuyer browser extension (the “SageBuyer Extension”), the SageBuyer website located at www.sagebuyer.ai and all other SageBuyer-branded websites (each, a "Website"), and other software applications and online services provided by SageBuyer (collectively, the "Service").
        </p>
        <div className="mt-10 max-w-2xl">
          <div id="accepting-the-terms">
            <a
              href={`${baseURL}#accepting-the-terms`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Accepting the Terms
            </a>
            <br />
            <p>
              By using any portion of the Service, you agree to comply with and be bound by this Agreement. The terms "we" or "our" or "us" refer to SageBuyer. You must be an individual and at least 18 years old, or the age of majority in your state, province, or country of residence, to use the Service. You may not use the Service or accept this Agreement if you are not of a legal age and able and competent to form a binding contract with SageBuyer. If you use the Service, you represent that you have the capacity to be bound by this Agreement. The Service is for personal, non-commercial use. You agree that you are not using the Service for or on behalf of any third-party, or for any commercial purpose.

            </p>
            <br />
            <p>
              THIS AGREEMENT CONTAINS (1) AN ARBITRATION PROVISION; (2) A WAIVER OF RIGHTS TO BRING A CLASS ACTION LAWSUIT AGAINST US; AND (3) A RELEASE BY YOU OF CLAIMS FOR DAMAGE AGAINST US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICE, TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. BY USING THE SERVICE, YOU AGREE TO THESE PROVISIONS.



            </p>
            <br />
          </div>

          <div id="ammendments-to-this-agreements">
            <a
              href={`${baseURL}#ammendments-to-this-agreements`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Amendments to this Agreement
            </a>
            <br />
            <p>
              SageBuyer may amend this Agreement or other policies that govern the Service from time to time. Any changes to the Agreement will be posted to https://www.sagebuyer.ai/terms, and the posted Agreement will indicate the date the Agreement was last revised. If you do not agree to the changes, you must uninstall any applicable SageBuyer software and stop using the Service. By continuing to use the Service after any changes to this Agreement are posted on the Website, you agree to be bound by those changes.

            </p>
            <br />
          </div>

          <div id="privacy-and-your-personal">
            <a
              href={`${baseURL}#privacy-and-your-personal`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Privacy and your Personal Information
            </a>
            <br />
            <p>
              The Service is subject to SageBuyer's privacy and data protection practices (the “SageBuyer Privacy Policy"). Please read SageBuyer's Privacy Policy found at https://www.sagebuyer.ai/privacy. Our SageBuyer Privacy Policy explains how SageBuyer collects, uses and shares your information when you visit our Website, use the SageBuyer Mobile App or otherwise access the Service. By using the Service, you consent to SageBuyer's collection, use, and sharing of your information as set forth in the SageBuyer Privacy Policy.

            </p>
            <br />
          </div>

          <div id="description-of-the-service">
            <a
              href={`${baseURL}#description-of-the-service`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Description of the Service
            </a>
            <br />
            <p>
              The Service includes a suite of tools that guide Users on the Environmental, Social, and Governance (ESG) performance of companies while shopping online. We will highlight products from companies with poor ESG records and suggest alternatives with better ESG ratings. Users can customize their experience by prioritizing specific ESG criteria on our dedicated site, influencing the extension's recommendations. SageBuyer offers a variety of ways to access aspects of our Service, including the Website, browser extension, the SageBuyer Mobile App. Not all aspects of our Service will be available through all channels or in all locations. For example, some aspects of our Service may only be available through the SageBuyer Extension.

            </p>
            <br />
            <p>
              In addition, you must become a Member to access some portions of
              our Service, specifically the ability to customize the Service in
              accordance with your personal values..
            </p>
            <br />
            <p>SageBuyer does not charge fees to you for its Service.</p>
            <br />
            <p>
              While we attempt to provide accurate descriptions for the products and other information shown within or through the Service, much of the information we display is provided by third parties that we do not control. We do not warrant that the product descriptions and related information shown through the Service (including the browser extension, Website, SageBuyer Mobile App) are accurate, complete, reliable, current, or error-free.

            </p>
            <br />
          </div>

          <div id="sage-buyer-mobile-app">
            <a
              href={`${baseURL}#sage-buyer-mobile-app`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              SageBuyer Mobile App
            </a>
            <br />
            <p>
              The SageBuyer Mobile App works on an application linked to a
              particular device and operating system, such as Apple's iOS
              operating system. Your use of the SageBuyer Mobile App may be
              subject to separate agreements you may enter into with your mobile
              device operating system provider (e.g., Apple®, Google® or
              Microsoft®), your mobile device manufacturer (e.g., Apple®,
              Samsung®), your mobile service carrier (e.g., AT&T® or Verizon®),
              and other parties involved in providing your mobile device service
              (which we individually refer to as a "Covered Third Party" and
              which we collectively refer to as "Covered Third Parties"). You
              agree to comply with all applicable third-party terms of service
              when using the SageBuyer Mobile App. We are not a party to those
              agreements and we have no responsibility for the products and
              services provided by third parties. You acknowledge and agree that
              this agreement is between you and SageBuyer, not with any Covered
              Third Party. You acknowledge and agree that we (and not any
              Covered Third Party) are solely responsible for the SageBuyer
              Mobile App and for providing any maintenance or support services
              for the SageBuyer Mobile App.
            </p>
            <br />
            <p>
              Covered Third Parties have no warranty obligations whatsoever with
              respect to the SageBuyer Mobile App, and any other claims, losses,
              liabilities, damages, costs or expenses attributable to any
              failure of the SageBuyer Mobile App to conform to any warranty
              provided by us (if any), will be our sole responsibility.
            </p>
            <br />
            <p>
              We, not any Covered Third Parties, are responsible for addressing
              any claims relating to the SageBuyer Mobile App, including, but
              not limited to: (i) product liability claims; (ii) any claim that
              the SageBuyer Mobile App fails to conform to any applicable legal
              or regulatory requirement; (iii) claims arising under consumer
              protection, privacy, or similar legislation; and (iv) intellectual
              property claims.
            </p>
            <br />
            <p>
              If you are using the SageBuyer Mobile App on an Apple® or Google®
              device, you acknowledge and agree that Apple® or Google® (as
              applicable), and their subsidiaries (as applicable), are third
              party beneficiaries of this Agreement, and that they will have the
              right (and will be deemed to have accepted the right) to enforce
              this agreement against you as a third-party beneficiary. Such
              rights may also accrue to other handset manufacturers and
              operating systems which participate in the SageBuyer Mobile App.
            </p>
            <br />
            <p>
              To the extent the other terms and conditions of the rest of this
              Agreement conflict with the terms and conditions of this section,
              the terms and conditions in this section apply, but solely with
              respect to your use of the SageBuyer Mobile App downloaded from
              the Apple App Store or Google Play Store.
            </p>
            <br />
          </div>

          <div id="sagebuyers-intellectual-property-rights">

            <a
              href={`/${baseURL}#sagebuyers-intellectual-property-rights`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              SageBuyer's Intellectual Property Rights
            </a>
            <br />
            <p> The contents of the Service, including its "look and feel" (e.g., text, graphics, images, logos and button icons), photographs, editorial content, notices, software (including html-based computer programs) and other material are protected under copyright, trademark and other laws. The contents of the Service belong or are licensed to SageBuyer or its software or content suppliers. SageBuyer grants you the right to view and use the Service subject to this Agreement. Any distribution, reprint or electronic reproduction of any content from the Service, other than as expressly permitted in this Agreement or with our prior written consent, is prohibited. If you would like to request such permission, shoot us an email at  <a className="text-blue-700" href="mailto@info@sagebuyer.ai">info@sagebuyer.ai</a>.
            </p>
            <br />
            <p>Your right to access and use the Service is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use our Service for lawful, internal, and non-commercial purposes. Subject to your compliance with this Agreement, SageBuyer hereby grants to you, a personal, worldwide, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to (i) use the software and content provided to you as part of the Service (for personal, non-commercial purposes); and (ii) download a single copy of our software for such use.
            </p>
            <br></br>
            <p>You agree that SageBuyer may use your feedback, suggestions, or ideas in any way, including in future modifications of the Service, other products or services, advertising or marketing materials (although any use of your personal information will be subject to the SageBuyer Privacy Policy).
            </p>
          </div>

          <div id="sagebuyers-intellectual-property-rights">

            <a
              href={`/${baseURL}#sagebuyers-intellectual-property-rights`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              SageBuyer's Intellectual Property Rights
            </a>
            <br />
            <p> The contents of the Service, including its "look and feel" (e.g., text, graphics, images, logos and button icons), photographs, editorial content, notices, software (including html-based computer programs) and other material are protected under copyright, trademark and other laws. The contents of the Service belong or are licensed to SageBuyer or its software or content suppliers. SageBuyer grants you the right to view and use the Service subject to this Agreement. Any distribution, reprint or electronic reproduction of any content from the Service, other than as expressly permitted in this Agreement or with our prior written consent, is prohibited. If you would like to request such permission, shoot us an email at  <a className="text-blue-700" href="mailto@info@sagebuyer.ai">info@sagebuyer.ai</a>.
            </p>
            <br />
            <p>Your right to access and use the Service is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use our Service for lawful, internal, and non-commercial purposes. Subject to your compliance with this Agreement, SageBuyer hereby grants to you, a personal, worldwide, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to (i) use the software and content provided to you as part of the Service (for personal, non-commercial purposes); and (ii) download a single copy of our software for such use.
            </p>
            <br></br>
            <p>You agree that SageBuyer may use your feedback, suggestions, or ideas in any way, including in future modifications of the Service, other products or services, advertising or marketing materials (although any use of your personal information will be subject to the SageBuyer Privacy Policy).
            </p>
          </div>


          <div id="technology-disclaimer">
            <a
              href={`${baseURL}#technology-disclaimer`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Technology Disclaimer
            </a>
            <br />
            <p>
              Your access and use of the Service may be interrupted from time to
              time due to third-party failures outside of our control (such as
              unavailability of general Internet infrastructure). Access and use
              may also be interrupted for periodic updating, maintenance or
              repair of the Service by SageBuyer. While we will make reasonable
              efforts to make the Service available to you, we do not promise
              that it will be available at all times.
            </p>
            <br />
            <p>
              You are responsible for providing the mobile device, wireless
              service plan, software, Internet connections, and/or other
              equipment or services that you need to download, install, and use
              the SageBuyer Mobile App. We do not guarantee that the SageBuyer
              Mobile App can be accessed and used on any particular mobile
              device or with any particular service plan. We do not guarantee
              that the SageBuyer Mobile App will be available in, or that orders
              for products can be placed from, any particular geographic
              location.
            </p>
            <br />
          </div>

          <div id="prohibited-activities">
            <a
              href={`${baseURL}#prohibited-activities`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Prohibited Activities
            </a>
            <br />
            <p>
              You represent, warrant, and agree that you will not contribute any
              content or otherwise use the Service or interact with the Service
              in a manner that:
            </p>
            <ul
              style={{ listStyle: "inside", listStyleType: "circle" }}
              className="mt-8 max-w-xl space-y-8 text-gray-600 legal"
            >
              <li>
                <span>
                  Infringes or violates the intellectual property rights or any
                  other rights of anyone else (including SageBuyer);
                </span>
              </li>
              <li>
                <span>Violates any law or regulation or this Agreement</span>
              </li>
              <li>
                <span>
                  Is harmful, fraudulent, deceptive, threatening, harassing,
                  defamatory, obscene, or otherwise objectionable;
                </span>
              </li>
              <li>
                <span>
                  Jeopardizes the security of your SageBuyer Account or anyone
                  else's such as allowing someone else to log in to the Service
                  as you
                </span>
              </li>

              <li>
                <span>
                  Attempts, in any manner, to obtain the password, account, or
                  other security information from any other User;
                </span>
              </li>

              <li>
                <span>
                  Violates the security of any computer network, or cracks any
                  passwords or security encryption codes
                </span>
              </li>

              <li>
                <span>
                  Runs Maillist, Listserv, any form of auto-responder or "spam"
                  on the Service, or any processes that run or are activated
                  while you are not logged into the Service, or that otherwise
                  interfere with the proper working of the Service (including by
                  placing an unreasonable load on the Service's infrastructure)
                  or that would bypass the navigational structure or
                  presentation of the Service
                </span>
              </li>

              <li>
                <span>
                  "Crawls," "scrapes," or "spiders" any page, data, or portion
                  of or relating to the Service or Content through use of manual
                  or automated means
                </span>
              </li>

              <li>
                <span>
                  Copies or stores any significant portion of the Content;
                </span>
              </li>

              <li>
                <span>
                  Decompiles, reverse engineers, or otherwise attempts to obtain
                  the source code or underlying ideas or information of or
                  relating to the Service or Circumvents, removes, alters,
                  deactivates, degrades or thwarts any protective or security
                  measure, or any content protections, which SageBuyer has
                  implemented as part of the Service.
                </span>
              </li>
            </ul>
            <br />
            <p>
              If you violate or infringe any of the rules above, we may
              immediately suspend or terminate your right to use or access the
              Service.
            </p>
            <br />
          </div>

          <div id="disclaimer-of-representations-and-warranties">
            <a
              href={`${baseURL}#disclaimer-of-representations-and-warranties`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Disclaimer of Representations and Warranties
            </a>
            <br />
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE CONTENT AND
              ALL SERVICES AND PRODUCTS ASSOCIATED WITH THE SERVICE OR PROVIDED
              THROUGH THE SERVICE (WHETHER OR NOT SPONSORED) ARE PROVIDED TO YOU
              ON AN "AS-IS" AND "AS AVAILABLE" BASIS. SageBuyer MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
              TO THE CONTENT OR OPERATION OF THE SERVICE OR OF THE SERVICE. YOU
              EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.
            </p>
            <br />
            <p>
              SageBuyer MAKES NO REPRESENTATIONS, WARRANTIES, CONDITIONS, OR
              GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY,
              RELIABILITY OR COMPLETENESS OF THE CONTENT ON THE SERVICE OR OF
              THE SERVICE (WHETHER OR NOT SPONSORED), AND EXPRESSLY DISCLAIMS
              ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT
              OR FITNESS FOR A PARTICULAR PURPOSE. SageBuyer MAKES NO
              REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT MAY BE
              AVAILABLE THROUGH THE SERVICE IS FREE OF INFECTION FROM ANY
              VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT
              CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE
              INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY
              SYSTEM, DATA OR PERSONAL INFORMATION. YOU ARE RESPONSIBLE FOR
              ENSURING THE ACCURACY OF ANY USER CONTENT THAT YOU PROVIDE, AND WE
              DISCLAIM ALL LIABILITY AND RESPONSIBILITY FOR THE ACCURACY OF SUCH
              CONTENT.
            </p>
            <br />
          </div>

          <div id="notification-disclaimer">
            <a
              href={`${baseURL}#notification-disclaimer`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Notification Disclaimer
            </a>
            <br />
            <p>
              You understand and agree that any notifications provided to you
              through the Service may be delayed or prevented by a variety of
              factors. SageBuyer does its best to provide notifications in a
              timely manner with accurate information. However, we neither
              guarantee the delivery nor the accuracy of the content of any
              notification. You also agree that SageBuyer shall not be liable
              for any delays, failure to deliver, or misdirected delivery of any
              notification; for any errors in the content of a notification; or
              for any actions taken or not taken by you or any third-party in
              reliance on a notification.
            </p>
            <br />
          </div>

          <div id="limitations-on-sageBuyers-liability">
            <a
              href={`${baseURL}#limitations-on-sageBuyers-liability`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Limitations on SageBuyer's Liability
            </a>
            <br />
            <p>
              SageBuyer SHALL IN NO EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO
              ANY THIRD PARTY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
              NEGLIGENCE) OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES,
              INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS,
              ARISING IN WHOLE OR IN PART FROM YOUR ACCESS TO THE SERVICE, YOUR
              USE OF THE SERVICE OR THIS AGREEMENT, OR FOR ANY LOSS OR DAMAGE
              CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED ON OR THROUGH THE
              SERVICE, EVEN IF SageBuyer HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS
              AGREEMENT, SageBuyer'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
              AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
              LIMITED TO $500.00 (FIVE HUNDRED UNITED STATES DOLLARS).
            </p>
            <br />
            <p>
              BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
              DAMAGES, IN SUCH STATES OR JURISDICTIONS, THE LIABILITY OF
              SageBuyer SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
              THEREFORE, THE FOREGOING LIMITATIONS SHALL APPLY TO THE MAXIMUM
              EXTENT PERMITTED BY LAW. NOTHING IN THIS AGREEMENT AFFECTS
              STATUTORY RIGHTS THAT CANNOT BE WAIVED OR LIMITED BY CONTRACT.
            </p>
            <br />
          </div>

          <div id="your-indemnification-of-sageBuyer">
            <a
              href={`${baseURL}#your-indemnification-of-sageBuyer`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Your Indemnification of SageBuyer
            </a>
            <br />
            <p>
              You shall defend, indemnify and hold harmless SageBuyer and its
              officers, directors, shareholders, and employees, from and against
              all claims and expenses, including but not limited to attorney's
              fees and other legal expenses, in whole or in part arising out of
              or attributable to your use of the Service (including any
              purchases you make within the SageBuyer Mobile App) or any breach
              of this Agreement by you or your violation of any law or the
              rights of a third-party when using the Service.
            </p>
            <br />
          </div>

          <div id="ending-your-relationship-with-sageBuyer">
            <a
              href={`${baseURL}#ending-your-relationship-with-sageBuyer`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Ending your Relationship with SageBuyer (and vice versa)
            </a>
            <br />
            <p>
              This Agreement will continue to apply until terminated by either
              you or SageBuyer as set out below.
            </p>
            <br />
            <p>
              If you want to terminate this Agreement, you may do so immediately
              at any time by closing your account for the Service. Please use
              the directions below to cancel your account, only if you have
              created a Membership. Otherwise just uninstall SageBuyer and stop
              visiting our Website and using the Service.
            </p>
            <br />
            <p>
              Email <a className="text-blue-700" href="mailto@info@sagebuyer.ai">info@sagebuyer.ai</a> to cancel your account. Upon doing so:
            </p>
            <br />
            <ul
              style={{ listStyle: "inside", listStyleType: "circle" }}
              className=" max-w-xl space-y-8 text-gray-600 legal"
            >
              <li className=" ml-x-3">
                your account will be closed and your ability to log in will be
                deactivated; and
              </li>
              <li>
                any data in our records will be retained subject to our privacy
                statement.
              </li>
            </ul>
            <br />
            <p>
              SageBuyer may immediately, at any time, terminate this Agreement
              (and therefore your right to access and use the Service) by
              notifying you (if safe to do so) to the e-mail address provided by
              you as part of your Registration Information.
            </p>
            <br />
            <p>
              ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE
              REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS
            </p>
            <br />
            <p>
              Arbitration. YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US
              (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD-PARTY) WITH REGARD
              TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION
              DISPUTES RELATED TO THIS AGREEMENT, YOUR USE OF THE SERVICE,
              AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY
              BINDING, INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION
              ASSOCIATION'S RULES FOR ARBITRATION OF CONSUMER-RELATED DISPUTES
              AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED,
              HOWEVER, THAT TO THE EXTENT THAT YOU HAVE IN ANY MANNER VIOLATED
              OR THREATENED TO VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS, WE MAY
              SEEK INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY STATE OR
              FEDERAL COURT IN THE STATE OF CALIFORNIA. DISCOVERY AND RIGHTS TO
              APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A
              LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY
              NOT BE AVAILABLE IN ARBITRATION.
            </p>
            <br />
            <p>
              If you wish to begin an arbitration proceeding, you must send a
              letter requesting arbitration and describing your claim to 147
              Tresana Blvd., Unit 91, Jupiter FL 33478.
            </p>
            <br />
            <p>
              As an alternative, you may bring your claim in your local "small
              claims" court, if permitted by that small claims court's rules and
              if within such court's jurisdiction, unless such action is
              transferred, removed or appealed to a different court. You may
              bring claims only on your own behalf. Neither you nor we will
              participate in a class action or class-wide arbitration for any
              claims covered by this agreement to arbitrate. BY AGREEING TO THIS
              ARBITRATION CLAUSE & CLASS ACTION WAIVER, YOU AND SageBuyer ARE
              GIVING UP THE RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR
              CLASS MEMBER ON ANY CLASS CLAIM THAT EACH SIDE MAY HAVE AGAINST
              THE OTHER INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY
              CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You also agree not to
              participate in claims brought in a private attorney general or
              representative capacity, or consolidated claims involving another
              person's account, if we are a party to the proceeding. This
              dispute resolution provision will be governed by the Federal
              Arbitration Act and not by any state law concerning arbitration.
              In the event the American Arbitration Association is unwilling or
              unable to set a hearing date within one hundred sixty (160) days
              of filing the case, then either we or you can elect to have the
              arbitration administered instead by the Judicial Arbitration and
              Mediation Services. Judgment on the award rendered by the
              arbitrator may be entered in any court having competent
              jurisdiction. Any provision of applicable law notwithstanding, the
              arbitrator will not have authority to award damages, remedies or
              awards that conflict with this Agreement. You agree that
              regardless of any statute or law to the contrary, any claim or
              cause of action arising out of, related to or connected with the
              use of the Service or this Agreement must be filed within one (1)
              year after such claim of action arose or be forever banned.
            </p>
            <br />
            <p>
              If you do not want to arbitrate disputes with SageBuyer and you
              are an individual, you may opt out of this ARBITRATION CLAUSE &
              CLASS ACTION WAIVER by sending an email to  <a className="text-blue-700" href="mailto@info@sagebuyer.ai">info@sagebuyer.ai</a>
              within thirty (30) days of the first date you access or use the
              Service. Severability. If the prohibition against class actions
              and other claims brought on behalf of third parties contained
              above is found to be unenforceable, then all of the preceding
              language in this Arbitration section will be null and void. This
              arbitration agreement will survive the termination of your
              relationship with us.
            </p>
            <br />
          </div>

          <div id="governing-law-and-forum-for-disputes">
            <a
              href={`${baseURL}##governing-law-and-forum-for-disputes`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Governing Law and Forum for Disputes
            </a>
            <br />
            <p>
              This Agreement, and your relationship with SageBuyer under this
              Agreement, shall be governed by the laws of the State of Florida
              in the United States of America without regard to its conflict or
              choice of laws provisions. Any dispute with SageBuyer, or its
              officers, directors, employees, agents or affiliates, arising
              under or in relation to this Agreement shall be resolved
              exclusively as specified in the ARBITRATION CLAUSE & CLASS ACTION
              WAIVER clause above, except with respect to imminent harm
              requiring temporary or preliminary injunctive relief in which case
              SageBuyer may seek such relief in any court with jurisdiction over
              the parties. You understand that, in return for agreement to this
              provision, SageBuyer is able to offer the Service at the terms
              designated, with little or no charge to you, and that your assent
              to this provision is an indispensable consideration to this
              Agreement.
            </p>
            <br />
            <p>
              You also acknowledge and understand that, with respect to any
              dispute with SageBuyer, its officers, directors, employees, agents
              or affiliates, arising out of or relating to your use of the
              Service or this Agreement:
            </p>
            <br />
            <p>
              YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and YOU ARE
              GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE
              ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO
              PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT
              INVOLVING ANY SUCH DISPUTE.
            </p>
            <br />
          </div>

          <div id="copyright-disputes-and-user-content">
            <a
              href={`/${baseURL}#copyright-disputes-and-user-content`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Copyright Disputes and User Content
            </a>
            <br />
            <p>
              You may have heard of the Digital Millennium Copyright Act (the
              "DMCA"), as it relates to online service providers, like
              SageBuyer, being asked to remove user generated material that
              allegedly violates someone's copyright. We respect others'
              intellectual property rights, and we reserve the right to delete
              or disable User Content alleged to be infringing, and to terminate
              the accounts of repeat alleged infringers; to review our complete
              Copyright Dispute Policy and learn how to report potentially
              infringing content, visit here. To learn more about the DMCA,
              visit here.
            </p>
            <br />
          </div>

          <div id="consumer-rights-notices">
            <a
              href={`/${baseURL}#consumer-rights-notices`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Consumer Rights Notices
            </a>
            <br />
            <p>
              California Consumer Rights Notice. Under California Civil Code
              Section 1789.3, California users of the Service receive the
              following specific consumer rights notice: The Complaint
              Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs may be contacted in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA
              95834, or by telephone at (800) 952- 5210.
            </p>
            <br />
          </div>

          <div id="assignment">
            <a
              href={`/${baseURL}#assignment`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Assignment
            </a>
            <br />
            <p>
              SageBuyer may assign, without recourse, any or all of its rights
              or obligations under this Agreement, including all or a portion of
              this Agreement.
            </p>
            <br />
          </div>
          <div id="miscellaneous">
            <a
              href={`/${baseURL}#miscellaneous`}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Miscellaneous
            </a>
            <br />
            <p>
              If any portion of this Agreement is deemed unlawful, void or
              unenforceable by any arbitrator or court of competent
              jurisdiction, this Agreement as a whole shall not be deemed
              unlawful, void or unenforceable, but only that portion of this
              Agreement that is unlawful, void or unenforceable shall be
              stricken from this Agreement.
            </p>
            <br />
            <p>
              You agree that if SageBuyer does not exercise or enforce any legal
              right or remedy which is contained in the Agreement (or which
              SageBuyer has the benefit of under any applicable law),this will
              not be taken to be a formal waiver of SageBuyer's rights and that
              those rights or remedies will still be available to SageBuyer.
            </p>
            <br />
            <p>
              All covenants, agreements, representations and warranties made in
              this Agreement shall survive your acceptance of this Agreement and
              the termination of this Agreement.
            </p>
            <br />
            <p>
              The term "including" in this Agreement means "including but not
              limited to."
            </p>
            <br />
          </div>
          <div id="contact-us">
            <a
              href={"/contact-us"}
              className="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
              aria-current="page"
            >
              Contact Us
            </a>
            <br />
            <p>
              SageBuyer welcomes comments, questions, concerns, or suggestions.
              Please send us feedback by emailing us at  <a className="text-blue-700" href="mailto@info@sagebuyer.ai">info@sagebuyer.ai</a> .
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TC;
