import React, { useEffect, useState } from "react";
import io from "socket.io-client";

const Logs = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

    socket.on("ai_model_requests", (message) => {
      setLogs((prevLogs) => [
        ...prevLogs,
        { channel: "ai_model_requests", message },
      ]);
    });

    socket.on("backend_requests", (message) => {
      setLogs((prevLogs) => [
        ...prevLogs,
        { channel: "backend_requests", message },
      ]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-4">Logs</h1>
      <div className="grid grid-cols-2 gap-4">
        {logs.map((log, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded shadow">
            <p className="font-bold">{log.channel}</p>
            <pre className="text-sm">
              {JSON.stringify(log.message, null, 2)}
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Logs;

// import React, { useEffect, useState } from "react";
// import { Header } from "../LandingPage/components/Header";
// import { Footer } from "../LandingPage/components/Footer";
// import api from "../../axios/axios";
// function Logs() {
//   const [infoFoundLogs, setInfoFoundLogs] = useState({
//     loading: true,
//     data: [],
//   });
//   const [infoFailedLogs, setInfoFailedLogs] = useState({
//     loading: true,
//     data: [],
//   });
//   const [gptFailedLogs, setGptFailedLogs] = useState({
//     loading: true,
//     data: [],
//   });

//   function getInfoFoundLogs() {
//     setInfoFoundLogs((prev) => ({ ...prev, loading: true }));
//     let data = [];
//     api
//       .get("/public/logs/company-info-found-logs/")
//       .then((response) => {
//         console.log(response);
//         data = response.data;
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//       .finally(() => {
//         setInfoFoundLogs((prev) => ({ loading: false, data }));
//       });
//   }

//   function getInfoFailedLogs() {
//     let data = [];
//     setInfoFailedLogs((prev) => ({ ...prev, loading: true }));
//     api
//       .get("/public/logs/company-info-not-found-logs/")
//       .then((response) => {
//         console.log(response);
//         data = response.data;
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//       .finally(() => {
//         setInfoFailedLogs((prev) => ({ loading: false, data }));
//       });
//   }

//   function getGPTFailedLogs() {
//     setGptFailedLogs((prev) => ({ ...prev, loading: true }));
//     let data = [];
//     api
//       .get("/public/logs/gpt-failed-logs/")
//       .then((response) => {
//         console.log(response);
//         data = response.data;
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//       .finally(() => {
//         setGptFailedLogs((prev) => ({ loading: false, data }));
//       });
//   }

//   useEffect(() => {
//     getGPTFailedLogs();
//     getInfoFailedLogs();
//     getInfoFoundLogs();
//   }, []);
//   return (
//     <div
//       className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
//       style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
//     >
//       <ul role="list" className="space-y-3">
//         {infoFoundLogs.loading ? (
//           <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
//             <div
//               className="text-center"
//               style={{
//                 minHeight: "300px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div role="status">
//                 <svg
//                   aria-hidden="true"
//                   className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
//                   viewBox="0 0 100 101"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
//                     fill="currentColor"
//                   />
//                   <path
//                     d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
//                     fill="currentFill"
//                   />
//                 </svg>
//                 <span className="sr-only">Loading...</span>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <li
//             className="overflow-hidden rounded-md bg-white px-6 py-4 shadow"
//             style={{
//               minHeight: "300px",
//               display: "grid",
//               alignItems: "center",
//               minWidth: "100%",
//             }}
//           >
//             <div className="px-4 sm:px-6 lg:px-8" style={{ display: "block" }}>
//               <div className="sm:flex sm:items-center">
//                 <div className="sm:flex-auto">
//                   <h1 className="text-base font-semibold leading-6 text-gray-900">
//                     Company found logger
//                   </h1>
//                   <p className="mt-2 text-sm text-gray-700">
//                     A list of all the logs where data is found successfully
//                   </p>
//                 </div>
//                 <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
//                   <button
//                     onClick={getInfoFoundLogs}
//                     type="button"
//                     className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//                   >
//                     Refresh
//                   </button>
//                 </div>
//               </div>
//               <div className="-mx-4 mt-8 sm:-mx-0">
//                 <table className="min-w-full divide-y divide-gray-300">
//                   <thead>
//                     <tr>
//                       <th
//                         scope="col"
//                         className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
//                       >
//                         Time
//                       </th>
//                       <th
//                         scope="col"
//                         className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
//                       >
//                         Category
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Company Id
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Brand
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Company Name
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Company Website
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Company Overall
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
//                       >
//                         Authenticated
//                       </th>
//                       {/* <th
//                           scope="col"
//                           className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
//                         >
//                           Role
//                         </th>
//                         <th
//                           scope="col"
//                           className="relative py-3.5 pl-3 pr-4 sm:pr-0"
//                         >
//                           <span className="sr-only">Edit</span>
//                         </th> */}
//                     </tr>
//                   </thead>
//                   {infoFoundLogs.data.length === 0 ? (
//                     <div
//                       className="text-center"
//                       style={{
//                         position: "absolute",
//                         left: "50%",
//                       }}
//                     >
//                       <span> No logs...</span>
//                     </div>
//                   ) : (
//                     <tbody
//                       className="divide-y divide-gray-200 bg-white"
//                       style={{
//                         maxHeight: "200px !important",
//                         overflow: "auto",
//                       }}
//                     >
//                       {infoFoundLogs.data.map((each) => (
//                         <tr>
//                           <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
//                             {new Date(each.field1).toUTCString()}
//                             <dl className="font-normal lg:hidden">
//                               <dt className="sr-only">Category</dt>
//                               <dd className="mt-1 truncate text-gray-700">
//                                 {each.field2}
//                               </dd>
//                               <dt className="sr-only sm:hidden">
//                                 Company name
//                               </dt>
//                               <dd className="mt-1 truncate text-gray-500 sm:hidden">
//                                 {each.field5}
//                               </dd>
//                             </dl>
//                           </td>
//                           <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
//                             {each.field2}
//                           </td>
//                           <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
//                             {each.field4}
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             {each.field5}
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             {each.field6}
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             <a
//                               className="text-blue-600 visited:text-purple-600"
//                               href={each.field8}
//                             >
//                               {each.field7}
//                             </a>
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             {each.field9}
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             {each.field10 ? "Yes" : "No"}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   )}
//                 </table>
//               </div>
//             </div>
//           </li>
//         )}

//         {infoFailedLogs.loading ? (
//           <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
//             <div
//               className="text-center"
//               style={{
//                 minHeight: "300px",
//                 display: "grid",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div role="status">
//                 <svg
//                   aria-hidden="true"
//                   className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
//                   viewBox="0 0 100 101"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
//                     fill="currentColor"
//                   />
//                   <path
//                     d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
//                     fill="currentFill"
//                   />
//                 </svg>
//                 <span className="sr-only">Loading...</span>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <li
//             className="overflow-hidden rounded-md bg-white px-6 py-4 shadow"
//             style={{
//               minHeight: "300px",
//               display: "grid",
//               alignItems: "center",
//               minWidth: "100%",
//             }}
//           >
//             {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> */}
//             <div className="px-4 sm:px-6 lg:px-8" style={{ display: "block" }}>
//               <div className="sm:flex sm:items-center">
//                 <div className="sm:flex-auto">
//                   <h1 className="text-base font-semibold leading-6 text-gray-900">
//                     Info Failed Logs
//                   </h1>
//                   <p className="mt-2 text-sm text-gray-700">
//                     A list of all the logs where data isn't found.
//                   </p>
//                 </div>
//                 <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
//                   <button
//                     onClick={getInfoFailedLogs}
//                     type="button"
//                     className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//                   >
//                     Refresh
//                   </button>
//                 </div>
//               </div>
//               <div className="-mx-4 mt-8 sm:-mx-0">
//                 <table className="min-w-full divide-y divide-gray-300">
//                   <thead>
//                     <tr>
//                       <th
//                         scope="col"
//                         className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
//                       >
//                         Time
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Category
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
//                       >
//                         Ticker
//                       </th>
//                       <th
//                         scope="col"
//                         className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
//                       >
//                         Brand
//                       </th>

//                       <th
//                         scope="col"
//                         className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
//                       >
//                         Authenticated
//                       </th>
//                     </tr>
//                   </thead>
//                   {infoFailedLogs.data.length === 0 ? (
//                     <div
//                       className="text-center"
//                       style={{
//                         position: "absolute",
//                         left: "50%",
//                       }}
//                     >
//                       <span> No logs...</span>
//                     </div>
//                   ) : (
//                     <tbody className="divide-y divide-gray-200 bg-white">
//                       {infoFailedLogs.data.map((each) => (
//                         <tr>
//                           <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
//                             {new Date(each.field1).toUTCString()}
//                             <dl className="font-normal lg:hidden">
//                               <dt className="sr-only">Title</dt>
//                               <dd className="mt-1 truncate text-gray-700">
//                                 {each.field2}
//                               </dd>
//                               <dt className="sr-only sm:hidden">Email</dt>
//                               <dd className="mt-1 truncate text-gray-500 sm:hidden">
//                                 {each.field3}
//                               </dd>
//                             </dl>
//                           </td>
//                           <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
//                             {each.field2}
//                           </td>
//                           <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
//                             {each.field5 !== "null" ? each.field5 : "N/A"}
//                           </td>
//                           <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
//                             {each.field4}
//                           </td>
//                           <td className="px-3 py-4 text-sm text-gray-500">
//                             {each.field6 ? "Yes" : "No"}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   )}
//                 </table>
//               </div>
//             </div>
//             {/* </div> */}
//           </li>
//         )}
//       </ul>
//     </div>
//   );
// }

// export default Logs;
