import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SageBanner from "../../components/SageBanner";
import EthicsQuestionnaire from "../Welcome/EthicsQuestionnaire";
import TopicSelection from '../Welcome/TopicSelection';

function Onboarding() {
  const [onboardingStep, setOnboardingStep] = useState('ethics');
  const navigate = useNavigate();

  const handleEthicsSubmit = () => {
    setOnboardingStep('topics');
  };

  const handleTopicsSubmit = () => {
    navigate('/home');
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-full w-full items-stretch min-w-full">
      <div className="h-full w-full mx-auto flex justify-center items-center">
        <div className="w-full flex justify-center">
          {onboardingStep === 'ethics' ? (
            <EthicsQuestionnaire onSubmit={handleEthicsSubmit} />
          ) : (
            <TopicSelection onSubmit={handleTopicsSubmit} />
          )}
        </div>
      </div>
      <div className="hidden lg:flex justify-center ">
        <SageBanner />
      </div>
    </div>
  );
}

export default Onboarding;